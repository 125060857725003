<template>
<div class="table-add">
    <div class="table-add-header">
        <h1>Создание Товары</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:600px;">
            <el-form ref="form" :model="form">
                <div class="form-au" v-if="langName=='ua'">
                    <div class="item">
                        <el-form-item>
                            <el-checkbox size="medium" v-model="checked" class="mt-20"> Активна</el-checkbox>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Короткое название</p>
                        <el-form-item>
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Полное название акции</p>
                        <el-form-item>
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <el-upload class="upload-demo" action="#" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                                <button type="button" class="button ink"> Загрузка главной картинки</button>
                            </el-upload>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Тело акции</p>
                        <el-form-item>
                            <el-input type="textarea" :rows="10" placeholder="Please input" v-model="form.name">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="item">
                        <p>Действие акции</p>
                        <div class="flex w-full items-center">
                            <el-form-item>
                                <el-date-picker class="w-full" v-model="value1" type="date" placeholder="Pick a day">
                                </el-date-picker>
                            </el-form-item>
                            <div class="date-line"></div>
                            <el-form-item>
                                <el-date-picker class="w-full" v-model="value1" type="date" placeholder="Pick a day">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="form-en" v-if="langName=='en'">
                    <div class="item">
                        <el-form-item>
                            <el-checkbox size="medium" v-model="checked" class="mt-20"> Активна</el-checkbox>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Короткое название</p>
                        <el-form-item>
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Полное название акции</p>
                        <el-form-item>
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <el-upload class="upload-demo" action="#" :on-preview="handlePreview" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :limit="3" :on-exceed="handleExceed" :file-list="fileList">
                                <button type="button" class="button ink"> Загрузка главной картинки</button>
                            </el-upload>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p> Тело акции</p>
                        <el-form-item>
                            <el-input type="textarea" :rows="10" placeholder="Please input" v-model="form.name">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div class="item">
                        <p>Действие акции</p>
                        <div class="flex w-full items-center">
                            <el-form-item>
                                <el-date-picker class="w-full" v-model="value1" type="date" placeholder="Pick a day">
                                </el-date-picker>
                            </el-form-item>
                            <div class="date-line"></div>
                            <el-form-item>
                                <el-date-picker class="w-full" v-model="value1" type="date" placeholder="Pick a day">
                                </el-date-picker>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin')"> {{$t('back')}} </button>
                    <button class="button save ml-10"> Создать</button>
                </div>
            </el-form>
        </div>

    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName:'ua',
            value1: '',
            form: {
                name: ''
            }
        }

    },
}
</script>

<style scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}
</style>
